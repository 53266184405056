import React from 'react'

/*
import gif01 from '../assets/gifs/Youk.gif'
import gif02 from '../assets/gifs/HoldingCourt.gif'
import gif03 from '../assets/gifs/EatGreen.gif'
import gif04 from '../assets/gifs/Why.gif'
*/


class GifSpin extends React.Component{

  constructor(props) {
  	super(props)
    this.backgrounds = this.props.srcArray.items
    this.state = { backgroundIndex: 0 }

    this.changeBackground = this.changeBackground.bind(this)
  }


componentDidMount() {

  console.log("mounted gifspin")
  this.timeout = setTimeout(
      this.changeBackground,
      this.props.animDuration * 1000
    )
}

componentWillUnmount() {
  if(this.timeout) clearTimeout(this.timeout)
}

changeBackground () {
    this.setState(function ({ backgroundIndex }) {
      const nextBackgroundIndex = ++backgroundIndex % this.backgrounds.length
      return { backgroundIndex: nextBackgroundIndex }
    }, function () {
      this.timeout = setTimeout(
        this.changeBackground,
        this.props.animDuration * 1000
      )
    })
  }



  render(){
    return (
    <img src={this.backgrounds[this.state.backgroundIndex].animage.url} alt="#" style={{width: '80%'}} />
  );
}
}

export default GifSpin
