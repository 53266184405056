import * as React from 'react'
import VideoPlayer from 'react-player/lazy'

export const YouTube = ( props ) => {
  const dimensions = {
    aspectRatio: "16/9",
    width: "clamp(200px, 90%, 640px)",
    paddingBottom: '1em'
  }

  let youTubeId = '404';
if(props.slice.primary.video_url.url)
  youTubeId = props.slice.primary.video_url.url.slice(32);



  const thumbnailUrl = "https://img.youtube.com/vi/" + youTubeId + "/0.jpg"

return(
  <>
  <h3>{props.slice.primary.video_title.text}</h3>
  <div style= {{...dimensions}}>
  <VideoPlayer
    url= {props.slice.primary.video_url.url}
    width= '100%'
    height= '100%'
    light= {thumbnailUrl}
     />
    </div>
    </>
)

}
