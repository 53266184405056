import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//  <div className="logo">
  //  <span>[A Logo]</span>
//  </div>


export const Header = props => {


  Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool,
  }

  const data = useStaticQuery(graphql`
    {
      allPrismicTab(sort: {fields: data___tab_title___text}) {
    nodes {
      data {
        tab_title {
          text
        }
        order
      }
    }
  }
      prismicHome {
        data {
          title {
            text
          }
          subtitle {
            text
          }
        }
      }
    }
  `)
const doc = data.prismicHome.data

let tabLabs = data.allPrismicTab.nodes.sort((a,b)=>a.data.order>b.data.order);

console.log(tabLabs)

let tabBar = (
  <>
    {tabLabs.map(ind => {
      return (
        <li>
          <button
          onClick={() => {
            props.onOpenArticle(ind.data.tab_title.text)
          }}
          style= {{color: 'white'}}
          >
            {ind.data.tab_title.text}
          </button>
        </li>
      )
    }
  )
  }
</>
)

  return(
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>

    <div className="content">
      <div className="inner">
        <h1>{doc.title.text}</h1>
        <p>
          {doc.subtitle.text}
        </p>
      </div>
    </div>
    <nav>
      <ul>
    {tabBar}
      </ul>
    </nav>
  </header>
)



}

export default Header
