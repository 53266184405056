import React from 'react'

import { SliceZone } from './SliceZone'

const Tab = (props) => {

  //console.log(props)

  return (
    <article
      id={props.id}
      className={`${props.article === props.id ? 'active' : ''} ${
        props.articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <h2 className="major" >{props.id}</h2>
      <SliceZone slices={props.content.body} />

      {props.close}
    </article>
  )

}



export default Tab
