import * as React from 'react'

export const Image = ( {slice} ) => {
return(
  <>
  <span className="image main">
    <img src={slice.primary.animage.url} alt={slice.primary.animage.alt} />
  </span>
</>
)

}
