import * as React from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'
import '../assets/scss/audioPlayer.module.scss'

export const SoundPlayer = ( {slice} ) => {
return(
  <>
  <h3>{slice.primary.audio_title.text}</h3>
  <AudioPlayer
         autoPlay={false}
         src={slice.primary.audio_file.url}
         style={
           {width: '80%'},
           {height: '6em'},
           {display: 'flex'},
           {showJumpControls: 'false'}
           //{color: '#868686 !important'}
         }
         customVolumeControls= {[]}
         customAdditionalControls={[]}
         />
</>
)

}
