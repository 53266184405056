import PropTypes from 'prop-types'
import React from 'react'
import Tab from './Tab'

class Main extends React.Component {





  render() {

    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )


    let content = (

      <>
        {
          this.props.documents.nodes.map( ind => (
            <Tab
              id= {ind.data.tab_title.text}
              close={close}
              isArticleVisible={this.props.isArticleVisible}
              timeout={this.props.timeout}
              articleTimeout={this.props.articleTimeout}
              article={this.props.article}
              onCloseArticle={this.props.handleCloseArticle}
              setWrapperRef={this.props.setWrapperRef}
              content= {ind.data}
            />
          )
        )}

      </>
    )

    console.log(this.props)

    return(
      <div
      ref={this.props.setWrapperRef}
      id="main"
      style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        {content}
      </div>

  )
  }



}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}



export default Main
