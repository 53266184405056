import React from 'react'
import { Image } from '../slices/Image'
import { Quote } from '../slices/Quote'
import { BodyText } from '../slices/BodyText'
import { YouTube } from '../slices/YouTube'
import { ImageWheel } from '../slices/ImageWheel'
import { SoundPlayer } from '../slices/SoundPlayer'

export const SliceZone = ( props ) => {

  const sliceComponents = {
    image: Image,
    quote: Quote,
    text: BodyText,
    youtube: YouTube,
    imagewheel: ImageWheel,
    audio: SoundPlayer,
  }

  console.log(props)

  return props.slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })
}
