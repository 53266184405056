import * as React from 'react'

export const Quote = ( {slice} ) => {

return(
  <>
  <h3>{slice.primary.a_quote.text}</h3>
  <h5><i>{slice.primary.attribution.text}</i></h5>
</>
)

}
