import * as React from 'react'
import { RichText } from 'prismic-reactjs'


export const BodyText = ( {slice} ) => {

  console.log('text')

return (<RichText render={slice.primary.bodytext.raw} />)



}
