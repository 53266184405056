import * as React from 'react'
import GifSpin from '../components/GifSpin'

export const ImageWheel = ( {slice} ) => {
return(
  <>
  <GifSpin animDuration={4} srcArray={slice}/>
</>
)

}
